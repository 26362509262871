<template>
  <div class="vip_report">
    <van-cell-group class="report_group" id="report">
      <van-cell
        class="field_box"
        :value="reportData.dataMonth"
        title="数据月份"
      />
      <van-cell
      class="field_box"
        :value="`${reportData.bgnDate || ''} 至 ${reportData.endDate || ''}`"
        title="数据日期"
      />
      <van-cell
        class="field_box"
        :value="reportData.totalFee"
        title="总电费（元）"
      />
      <van-cell
        class="field_box"
        :value="reportData.totalEnergy"
        title="总电量（度）"
      />
      <van-cell
        class="field_box long_field_box"
        :value="`${(reportData.totalFee / reportData.totalEnergy).toFixed(2) || ''}元/度`"
        title="平均电价（=总电费/总电量）"
      />
      <van-cell 
        class="field_box" 
        title="电力结算情况" 
        :value="reportData.longDesc" 
      />
      <van-row class="field_box long_field_box">
        <van-col span="8" class="left_span">电力结算单原图</van-col>
        <van-col span="16" class="right_span">
          <img v-for="(item, index) in reportData.attachList" :key="index" :src="item.path" @click="showImg(item, index)" class="img_style" />
        </van-col>
      </van-row>
    </van-cell-group>
    <h4 class="title_head"><span class="border_span"></span><font>结算事项</font></h4>
    <div class="fontBox">
      <van-collapse v-model="activeNames">
        <van-collapse-item v-for="(item, index) in collapseList" :key="index" :title="item.title" :value="item.value" :name="index">
          <div class="child_box">
            <van-steps direction="vertical" :active="-1">
              <van-step v-if="item.key === 'refundFeeList'">
                <div class="box_child">
                  <h3><span class="left_span">备注：计算公式</span></h3>
                  <ul>
                    <li>退款金额= (预缴电费单价-电力平均电价)*用电量</li>
                  </ul>
                </div>
              </van-step>
              <van-step v-if="item.key === 'apportionFeeList'">
                <div class="box_child">
                  <h3><span class="left_span">备注：计算公式</span></h3>
                  <ul>
                    <li>1. 公摊总费用=该时段内公摊电表电量*电力平均电价；</li>
                    <li>2.各电表公摊占比=该时段内各商户电表电量/∑（该时段内所有商户电表电量）；</li>
                    <li>3.公摊费=公摊总费用*各电表公摊占比。</li>
                  </ul>
                </div>
              </van-step>
              <van-step v-for="(obj, index) in item.list" :key="index">
                <div class="box_child">
                  <h3>
                    <span class="left_span">{{obj.dataTime || obj.dataDate || `${obj.bgnDate} 至 ${obj.endDate}`}}</span>
                    <span :class="item.key === 'refundFeeList' || item.key === 'apportionFeeList' ? 'right_span_1' : 'right_span'">
                      <font v-if="item.key === 'rechargeList'">{{obj.payWay | filterPayState}}</font>
                      <font v-else-if="item.key === 'feeList'">电费(元)：</font>
                      <font v-else-if="item.key === 'apportionFeeList'">公摊费(元)：</font>
                      <font v-else-if="item.key === 'refundFeeList'">{{item.title}}：</font>
                      <font>{{obj.fee || obj.refundFee}}</font>
                    </span>
                  </h3>
                  <ul v-if="item.key === 'feeList'">
                    <li>
                      <span class="left_child">电表编号：{{obj.assetNo}}</span>
                    </li>
                    <li>
                      <span class="left_child">电量（度）：{{obj.energy}}</span>
                    </li>
                  </ul>

                  <ul v-else-if="item.key === 'apportionFeeList'">
                    <li>
                      <span class="left_child">公摊电表编号：{{obj.assetNo}}</span>
                    </li>
                    <li>
                      <span class="left_child">公摊方式：{{obj.apportionType | apportionTypeFilter}}</span>
                    </li>
                    <li>
                      <span class="left_child">公摊电量（度）：{{obj.apportionEnergy}}</span>
                    </li>
                    <li>
                      <span class="left_child">电力平均电价（元/度）：{{obj.avgPrice}}</span>
                    </li>
                    <li>
                      <span class="left_child">公摊总费用（元）：{{obj.fee}}</span>
                    </li>
                    <li>
                      <span class="left_child">该电表电量（度）：{{obj.energy}}</span>
                    </li>
                    <li>
                      <span class="left_child">所有商户电表电量（度）：{{obj.totalEnergy}}</span>
                    </li>
                    <li>
                      <span class="left_child">公摊占比：{{obj.apportionRate | apportionRateFilter}}</span>
                    </li>
                    <li>
                      <span class="left_child  blue_font">公摊费用（元）：{{obj.fee}} * {{obj.apportionRate | apportionRateFilter}} = {{parseFloat((obj.fee * obj.apportionRate).toFixed(2))}}</span>
                    </li>
                  </ul>

                  <ul v-if="item.key === 'refundFeeList'">
                    <li>
                      <span class="left_child">电表编号：{{obj.assetNo}}</span>
                    </li>
                    <li>
                      <span class="left_child">预缴单价（元/度）：{{obj.prePrice}}</span>
                    </li>
                    <li>
                      <span class="left_child">平均单价（元/度）：{{obj.actualPrice}}</span>
                    </li>
                    <li>
                      <span class="left_child">用电量（度）：{{obj.energy}}</span>
                    </li>
                    <li>
                      <span class="left_child blue_font">退款金额（元）：（ {{obj.prePrice}} - {{obj.actualPrice}} ）* {{obj.energy}} ={{obj.refundFee}}</span>
                    </li>
                  </ul>
                </div>
              </van-step>
            </van-steps>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import api from '@/api/index'
  const { getWxConfig, fetchData } = api
  import { mapState, mapActions, mapGetters } from 'vuex'
  import Vue from 'vue'
  import { ImagePreview } from 'vant'
  import { Progress } from 'vant'

  Vue.use(Progress)
  Vue.use(ImagePreview)
  export default {
    data() {
      return {
        pageQuery: {},
        activeNames: [],
        reportData: {},
        collapseList: [
          {
            title: '充值金额（元）',
            value: 0,
            key: 'rechargeList',
            totalKey: 'totalRecharge',
            list: []
          },
          {
            title: '电费金额（元）',
            value: 0,
            key: 'feeList',
            totalKey: 'totalEnergyFee',
            list: []
          },
          {
            title: '公摊金额（元）',
            value: 0,
            key: 'apportionFeeList',
            totalKey: 'totalApportionFee',
            list: []
          },
          {
            title: '退款金额（元）',
            value: 0,
            key: 'refundFeeList',
            totalKey: 'totalRefundFee',
            list: []
          }
        ]
      }
    },
    filters: {
      filterPayState(status) {
        const statusMap = {
          XJ: '现金',
          POS: '刷卡',
          ZFB: '支付宝',
          WX: '微信',
          DG: '对公转账',
          GZH: '公众号支付',
          OTHER: '其他',
          BALANCE_OUT: '余额移出',
          BALANCE_IN: '余额移入'
        }
        return statusMap[status]
      },
      apportionTypeFilter(status) {
        const statusMap = {
          AUTOMATIC: '自动',
          MANUAL: '手动'
        }
        return statusMap[status]
      },
      apportionRateFilter(status) {
        return `${status * 100}%`
      }
    },
    computed: {
      ...mapState(['user'])
    },
    created() {
      this.pageQuery = this.$route.query && this.$route.query.reportDetailId ? this.$route.query : { reportDetailId: '124b6658-da14-402c-aab2-c101191e9513' }
      this.getReportData()
    },
    methods: {
      showImg(item, startPosition) {
        const images = this.reportData.attachList && this.reportData.attachList.length ? this.reportData.attachList.map(item => {
          const url = item.path
          return url
        }) : []
        ImagePreview({
          images,
          startPosition: startPosition,
          onClose() {
            // do something
          }
        })
      },
      setCollapseList() {
        this.collapseList.map(item => {
          item.value = this.reportData[item.totalKey]
          item.list = this.reportData[item.key]
          return item
        })
      },
      getReportData() {
        const _self = this
        fetchData(
          {},
          `/running/electricDetail/${this.pageQuery.reportDetailId}`,
          'get'
        ).then(res => {
          this.reportData = res
          this.setCollapseList()
          console.log(res, 'res')
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .box_child {
    width:100%;
    height: auto;
    overflow: hidden;
    color:#666;
    .blue_font {
      color:#68A6FD;
    }
    ul {
      color: #999;
      float: left;
      width:calc(100% - 48px);
      border:1px solid #DEDEDE;
      padding: 20px;
      margin: 20px 0;
      li {
        float: left;
        width: 100%;
        height: auto;
        line-height: 2rem;
      }
    }
    h3 {
      margin-top:0;
      color:#666666;
      font-weight: 400;
      .left_span {
        color:#666666;
        float: left;
      }
      .right_span {
        color:#666666;
        float:right;
      }
      .right_span_1 {
        display: inline-block;
        width: 100%;
        color:#666666;
        float:left;
        margin-top: 20px;
        display: none;
      }
    }
  }
  .vip_report {
    width: 100%;
    height: 100%;
    .left_span {
      color:#999FAA;
    }
    .right_span {
      color:#333;
      text-align: right;
    }
  }
  .img_style {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  .title_head {
    display:inline-block;
    background: #F5F5F5;
    margin: 0;
    padding:1rem 20px;
    width: 100%;
    line-height: 1.4rem;
    height: 1.4rem;
    .border_span {
      display: inline-block;
      width: 10px;
      height:1.4rem;
      float: left;
      margin-right: 20px;
      border-radius: 50px;
      background:#68A6FD;
    }
    font {
      float: left;
    }
  }
</style>
